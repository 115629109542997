import React from 'react';
import { useRouter } from 'next/compat/router';
import { usePrivy } from 't2-keystone/packages/privyAuthentication/src/hooks/usePrivy';
import Dropdown from '../Dropdown/Dropdown';
import { getSignInPageUrl } from '../../services/pagesUrl/getSignInPageUrl';
import { showNotification } from '../NotificationBanner/showNotification';
import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';
import NavbarUserDropdownItems from './NavbarUserDropdownItems';
import DropdownLabel from '../Dropdown/DropdownLabel';
import getUserDisplayName from '../../../utils/getUserDisplayName';
import { TDropdownState } from '../Dropdown/types';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { getSettingsPageUrl } from '../../services/pagesUrl/getSettingsPageUrl';
import { getUserEditPageUrl } from '../../services/pagesUrl/getUserEditPageUrl';
import IconButton from '../IconButton/IconButton';
import { SettingsSubpage } from '../UserSettings/constants';

export interface INavbarUserDropdownProps {
  publicAddress: string;
  profileImage: TCloudinaryImageShape;
  showUserMenu: boolean;
  id: string;
  canAccessApp: boolean;
  username: string;
  name?: string;
  cumulativeTimePoints: number;
  refetchUserDetails: () => void;
  onHelpClick: () => void;
}

const NavbarUserDropdown: React.FC<INavbarUserDropdownProps> = ({
  username = '',
  profileImage,
  id,
  refetchUserDetails,
  canAccessApp,
  onHelpClick,
  cumulativeTimePoints,
  name,
  publicAddress,
}) => {
  const { sendEvent } = useAnalytics();
  const router = useRouter();
  const { logout } = usePrivy();

  const copyToClipboard = () => {
    navigator?.clipboard?.writeText(publicAddress);
    showNotification({
      title: 'Copied',
      message: 'Your address has been copied to the clipboard',
      type: 'success',
    });
  };

  const handleLogout = async () => {
    await logout();
    router?.push(getSignInPageUrl());
  };

  const settingsPageUrl = getFeatureFlag('profileSettings')
    ? getSettingsPageUrl(SettingsSubpage.profile)
    : getUserEditPageUrl(id);

  const labelContent = (
    <div title={name || username} className="overflow-hidden text-ellipsis whitespace-nowrap w-[75px] md:w-[100px]">
      {getUserDisplayName({ name, username })}
    </div>
  );

  const handleDropdownStateChange = ({ isOpen }: TDropdownState) => {
    if (isOpen) {
      sendEvent('navbar_user_menu_dropdown_open');
    } else {
      sendEvent('navbar_user_menu_dropdown_close');
    }
  };

  return (
    <div className="flex items-center gap-2 lg:gap-8 leading-none">
      <Dropdown
        onStateChange={handleDropdownStateChange}
        position="right"
        renderLabel={({ isOpen }) => (
          <>
            <DropdownLabel
              className="pr-1 !py-1 hidden md:flex"
              label={labelContent}
              rightAdornment={
                <div className="ml-2">
                  <UserProfileImage size="small" profileImage={profileImage} className="md:w-8 md:h-8" />
                </div>
              }
              isActive={isOpen}
            />
            <IconButton tag="div" color="white" className="md:hidden">
              <UserProfileImage size="small" profileImage={profileImage} />
            </IconButton>
          </>
        )}
        menu={
          <NavbarUserDropdownItems
            settingsPageUrl={settingsPageUrl}
            copyToClipboard={copyToClipboard}
            publicAddress={publicAddress}
            handleLogout={handleLogout}
            refetchUserDetails={refetchUserDetails}
            canAccessApp={canAccessApp}
            onHelpClick={onHelpClick}
            user={{ name, username, profileImage, cumulativeTimePoints }}
          />
        }
      />
    </div>
  );
};

export default NavbarUserDropdown;
