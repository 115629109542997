import { CORS_ORIGIN } from '../../../../config';

/**
 * Sanitizes redirect URLs to prevent open redirect vulnerabilities
 * Only allows internal paths and whitelisted domains from CORS configuration
 * @param redirectUrl - The URL to sanitize
 * @returns A safe URL string or '/' if invalid
 */
export const getSanitizedRedirectUrl = (redirectUrl?: string): string => {
  if (!redirectUrl) {
    return '/';
  }

  try {
    if (redirectUrl.startsWith('/')) {
      return redirectUrl;
    }

    const url = new URL(redirectUrl);
    const isAllowedDomain = CORS_ORIGIN.some((origin) => {
      if (origin instanceof RegExp) {
        return origin.test(url.origin);
      }
      return url.origin === origin;
    });

    if (!isAllowedDomain) {
      return '/';
    }

    return `${url.pathname}${url.search}`;
  } catch (e) {
    return '/';
  }
};
