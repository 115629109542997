import { TMenuButton } from '../SettingsMenu/types';
import { getSettingsPageUrl } from '../../services/pagesUrl/getSettingsPageUrl';
import ProfileIcon from './icons/ProfileIcon';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';
import PublishingIcon from './icons/PublishingIcon';
import { NotificationsIcon } from './icons/NotificationsIcon';

export enum SettingsSubpage {
  profile = 'profile',
  publishing = 'publishing',
  notifications = 'notifications',
}

export const menuButtons: TMenuButton[] = [
  {
    title: SettingsSubpage.profile,
    url: getSettingsPageUrl(SettingsSubpage.profile),
    icon: <ProfileIcon />,
    shouldShow: getFeatureFlag('profileSettings'),
  },
  {
    title: SettingsSubpage.publishing,
    url: getSettingsPageUrl(SettingsSubpage.publishing),
    icon: <PublishingIcon />,
    shouldShow: getFeatureFlag('arweaveIntegration'),
  },
  {
    title: SettingsSubpage.notifications,
    url: getSettingsPageUrl(SettingsSubpage.notifications),
    icon: <NotificationsIcon />,
    shouldShow: getFeatureFlag('emailNotifications'),
  },
];
