import React from 'react';
import { User } from 't2-graphql-types';
import DropdownItem from '../Dropdown/DropdownItem';
import { inTextEllipsisCenter } from '../../../utils/text-ellipsis-center';
import DropdownSeparator from '../Dropdown/DropdownSeparator';
import DropdownClickableItem from '../Dropdown/DropdownClickableItem';
import ProtectWithLogin from '../ProtectWithLogin/ProtectWithLogin';
import SettingsIcon from '../Icons/SettingsIcon';
import { CopyIcon } from '../Icons/CopyIcon';
import ExitIcon from '../Icons/exit';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';
import HelpOnboardingMenuItem from './HelpOnboardingMenuItem';
import { NavbarUserDetailsDropdownItem } from './NavbarUserDetailsDropdownItem';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

type TNavbarUserDropdownItemsProps = {
  publicAddress: string;
  settingsPageUrl: string;
  copyToClipboard: () => void;
  handleLogout: () => void;
  refetchUserDetails: () => void;
  canAccessApp: boolean;
  onHelpClick: () => void;
  username?: string;
  profileImage?: TCloudinaryImageShape;
  cumulativeTimePoints?: number;
  user: Pick<User, 'username' | 'name' | 'profileImage' | 'cumulativeTimePoints'>;
};

const NavbarUserDropdownItems: React.FC<TNavbarUserDropdownItemsProps> = ({
  publicAddress,
  settingsPageUrl,
  copyToClipboard,
  handleLogout,
  canAccessApp,
  onHelpClick,
  user,
}) => {
  const { sendEvent } = useAnalytics();
  const handleClick = (itemName: string) => () => {
    sendEvent('navbar_user_menu_dropdown_item_click', { item_name: itemName });
  };
  const ITEMS = [
    {
      shouldDisplay: canAccessApp,
      itemName: 'my_profile',
      element: (
        <NavbarUserDetailsDropdownItem
          profileImage={user?.profileImage as TCloudinaryImageShape}
          timePoints={user?.cumulativeTimePoints || 0}
          username={user?.username || undefined}
        />
      ),
    },
    {
      shouldDisplay: canAccessApp,
      itemName: 'settings',
      element: (
        <ProtectWithLogin>
          <DropdownClickableItem
            type="link"
            leftAdornment={<SettingsIcon className="w-4 h-4 md:w-6 md:h-6" />}
            href={settingsPageUrl}
          >
            Settings
          </DropdownClickableItem>
        </ProtectWithLogin>
      ),
    },
    {
      shouldDisplay: getFeatureFlag('onboardingModal') && canAccessApp,
      itemName: 'help',
      element: <HelpOnboardingMenuItem onClick={onHelpClick} />,
    },
    {
      shouldDisplay: canAccessApp,
      itemName: 'wallet_address',
      element: (
        <DropdownClickableItem leftAdornment={<CopyIcon className="w-4 h-4 md:w-6 md:h-6" />} onClick={copyToClipboard}>
          {inTextEllipsisCenter(publicAddress, 4, 4)}
        </DropdownClickableItem>
      ),
    },
    {
      shouldDisplay: true,
      element: <DropdownSeparator />,
    },
    {
      shouldDisplay: true,
      itemName: 'logout',
      element: (
        <DropdownClickableItem leftAdornment={<ExitIcon className="w-4 h-4 md:w-6 md:h-6" />} onClick={handleLogout}>
          Log out
        </DropdownClickableItem>
      ),
    },
  ];
  return (
    <>
      {ITEMS.map(
        ({ itemName, shouldDisplay, element }, index) =>
          shouldDisplay && (
            // eslint-disable-next-line react/no-array-index-key
            <DropdownItem onClickCapture={itemName ? handleClick(itemName) : undefined} key={`${itemName}${index}`}>
              {element}
            </DropdownItem>
          ),
      )}
    </>
  );
};
export default NavbarUserDropdownItems;
