import { ReactNode } from 'react';
import { useAsidePanelContext } from './contexts/AsidePanelContext';
import { Sheet } from '../Sheet/Sheet';
import { TSheetProps } from '../Sheet/types';

type TAsidePanelContainerProps = Pick<TSheetProps, 'placement' | 'rounded'> & {
  children: ReactNode;
  containerClassName?: string;
};

export const AsidePanelContainer = ({
  children,
  containerClassName,
  placement,
  rounded,
}: TAsidePanelContainerProps) => {
  const { isOpen, closeAsidePanel } = useAsidePanelContext();

  return (
    <Sheet
      isOpen={isOpen}
      onClose={closeAsidePanel}
      containerClassName={containerClassName}
      placement={placement}
      rounded={rounded}
    >
      {children}
    </Sheet>
  );
};
