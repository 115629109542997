'use client';

import React, { Fragment, useEffect, useMemo } from 'react';
import { useRouter } from 'next/compat/router';
import cx from 'classnames';
import { INavbarUserDropdownProps } from '../NavbarAccountSection/NavbarUserDropdown';
import { NavbarAccountSection } from '../NavbarAccountSection/NavbarAccountSection';
import Container from '../Container/Container';
import useDetectScrollDirection, { ScrollDirection } from '../DiscordButton/hooks/useDetectScrollDirection';
import { useFullScreenMode } from '../../hooks/useFullScreenMode';
import { PUBLIC_URL } from '../../../config';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { useHasPermission } from '../Permissions/hooks/useHasPermission';
import { useNavbarHeight } from './hooks/useNavbarHeight';
import NavbarNewArticlePostLink from './NavbarNewArticlePostLink';
import NavbarTerritoriesDropdown from '../NavbarTerritorySection/NavbarTerritoriesDropdown';
import { NavbarSection } from './NavbarSection';
import { NavbarLogoLink } from './NavbarLogoLink';
import NavbarWrapper from './NavbarWrapper';
import NavbarTimePointsLabel from './NavbarTimePointsLabel';
import { NavbarNotificationBellContainer } from '../NavbarNotificationBell/NavbarNotificationBellContainer';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';
import { AsidePanelContainer } from '../AsidePanel/AsidePanelContainer';
import { NotificationsListContainer } from '../Notification/NotificationsListContainer';
import { NavbarShareButton } from './NavbarShareButton';
import { NavbarFullScreenButton } from './NavbarFullScreenButton';
import NavbarRightSectionPlaceholder from './NavbarRightSectionPlaceholder';
import { NavbarSearchButton } from './NavbarSearchButton';
import { NavbarLeftSectionPlaceholder } from './NavbarLeftSectionPlaceholder';
import { useModal } from '../Modal/useModal';
import { SearchFormContainer } from '../NavbarModalComponent/SearchFormContainer';
import { getTerritoryDiscoveryPageUrl } from '../../services/pagesUrl/getTerritoryDiscoveryPageUrl';

export interface INavbarProps {
  user?: INavbarUserDropdownProps;
  showUserMenu?: boolean;
  themeVariant?: 'light' | 'beige';
  showFullScreenModeButton?: boolean;
  showLoginButton?: boolean;
  showShareButton?: boolean;
  showAccountNavbarSection?: boolean;
  userArticleTimePoints?: number;
  showTerritoriesMenu?: boolean;
  showNotifications?: boolean;
  searchEnabled?: boolean;
  isArticleAuthor?: boolean;
  isArticleDraft?: boolean;
  noWidthLimit?: boolean;
}

const Navbar: React.FC<INavbarProps> = ({
  themeVariant = 'light',
  showUserMenu = true,
  showFullScreenModeButton = false,
  showLoginButton = true,
  showShareButton = false,
  showAccountNavbarSection = true,
  userArticleTimePoints = 0,
  showTerritoriesMenu = true,
  showNotifications = true,
  isArticleAuthor = false,
  isArticleDraft = false,
  searchEnabled = true,
  noWidthLimit,
}) => {
  const scrollDirection = useDetectScrollDirection();
  const { isFullScreen, enableFullScreen, disableFullScreen } = useFullScreenMode();
  const router = useRouter();
  const {
    isAuthenticated,
    loading: isAuthenticating,
    refetch,
    authenticatedUserId,
  } = useReactiveAuthenticatedUser() || {};
  const hasPermission = useHasPermission();

  const isOnArticlePage = router?.pathname === '/article/[articleSlug]';
  const isOnPromptPage = router?.asPath?.includes('/prompt/');
  const isOnTerritoryPage = router?.asPath?.includes('/t/');
  const isOnHomePage = router?.asPath === '/';
  const isOnTerritoryDiscoveryPage = router?.asPath?.includes(getTerritoryDiscoveryPageUrl());
  const roundedUserArticleTimePoints = Math.floor(userArticleTimePoints);
  const {
    ModalComponent: SearchModalComponent,
    isOpen,
    openModal: openSearchModal,
    closeModal: closeSearchModal,
  } = useModal();

  const marketingSiteUrl = PUBLIC_URL;
  const navbarHeight = useNavbarHeight();
  const translateYValue = useMemo(
    () => (scrollDirection === ScrollDirection.up ? 0 : -navbarHeight),
    [scrollDirection, navbarHeight],
  );

  const navbarLeftSectionButtons = [
    {
      key: 'territoriesDropdown',
      shouldDisplay: getFeatureFlag('territoryPageV2') ? showTerritoriesMenu : isAuthenticated && showTerritoriesMenu,
      element: <NavbarTerritoriesDropdown userId={authenticatedUserId} />,
    },
    {
      key: 'searchButton',
      shouldDisplay:
        // TODO: refactor for composability
        searchEnabled &&
        (isOnTerritoryPage || isOnArticlePage || isOnPromptPage || isOnHomePage || isAuthenticated) &&
        !isOnTerritoryDiscoveryPage &&
        getFeatureFlag('search'),
      element: <NavbarSearchButton openModal={openSearchModal} isOpen={isOpen} closeModal={closeSearchModal} />,
    },
  ];

  const navbarRightSectionButtons = [
    {
      key: 'articleTimePointsEarned',
      shouldDisplay: isOnArticlePage && !isAuthenticating && !isArticleAuthor && !isArticleDraft,
      element: (
        <NavbarTimePointsLabel isAuthenticated={isAuthenticated} userArticleTimePoints={roundedUserArticleTimePoints} />
      ),
    },
    {
      key: 'shareArticleButton',
      shouldDisplay: showShareButton,
      element: <NavbarShareButton />,
    },
    {
      key: 'showFullScreenModeButton',
      shouldDisplay: showFullScreenModeButton,
      element: <NavbarFullScreenButton onClick={enableFullScreen} />,
    },
    {
      key: 'newPostButton',
      shouldDisplay:
        (hasPermission('GLOBAL_CAN_CREATE_ARTICLE') || hasPermission('TERRITORY_CAN_CREATE_POST')) && !isOnArticlePage,
      element: <NavbarNewArticlePostLink />,
    },
    {
      key: 'notificationBell',
      shouldDisplay: showNotifications && getFeatureFlag('inAppNotifications') && isAuthenticated,
      element: <NavbarNotificationBellContainer />,
    },
    {
      key: 'accountSectionDropdown',
      shouldDisplay: showAccountNavbarSection,
      element: (
        <NavbarAccountSection
          showLoginButton={showLoginButton}
          showUserMenu={showUserMenu}
          refetchAuthenticatedItem={refetch!}
        />
      ),
    },
  ];

  useEffect(() => () => disableFullScreen(), [router?.asPath]);

  return (
    <>
      <NavbarWrapper themeVariant={themeVariant} translateYValue={translateYValue}>
        <Container
          className={cx('', {
            'max-w-none': noWidthLimit,
          })}
        >
          <div className="h-[72px] col-span-full flex gap-x-4 md:gap-x-5 py-3 justify-between">
            <NavbarSection>
              <NavbarLogoLink
                isDisabled={!!isAuthenticating}
                href={isAuthenticated && hasPermission('GLOBAL_CAN_ACCESS_APP') ? '/' : marketingSiteUrl}
              />
              {isAuthenticating ? (
                <NavbarLeftSectionPlaceholder />
              ) : (
                navbarLeftSectionButtons.map(
                  ({ shouldDisplay, element, key }) => shouldDisplay && <Fragment key={key}>{element}</Fragment>,
                )
              )}
            </NavbarSection>
            <NavbarSection isVisible={!isFullScreen}>
              {isAuthenticating ? (
                <NavbarRightSectionPlaceholder />
              ) : (
                navbarRightSectionButtons.map(
                  ({ shouldDisplay, element, key }) => shouldDisplay && <Fragment key={key}>{element}</Fragment>,
                )
              )}
            </NavbarSection>
          </div>
        </Container>
      </NavbarWrapper>
      {getFeatureFlag('inAppNotifications') && (
        <AsidePanelContainer containerClassName="sm:w-[472px] bg-white-900" placement="right">
          <NotificationsListContainer />
        </AsidePanelContainer>
      )}
      <SearchModalComponent
        containerClassName="sm:!p-1 sm:!rounded-[12px]"
        className="!z-[60]"
        closeButtonProps={{ className: 'sm:hidden' }}
      >
        <SearchFormContainer onFormSubmit={closeSearchModal} />
      </SearchModalComponent>
    </>
  );
};

export default Navbar;
