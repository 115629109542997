import classNames from 'classnames';
import { stopEventPropagation } from 'utils/stopEventPropagation';
import { CloseSheetButton } from './CloseSheetButton';
import { TSheetProps } from './types';

export const Sheet = ({ children, isOpen, onClose, containerClassName, placement = 'right', rounded }: TSheetProps) => (
  <div
    className={classNames(
      'fixed top-0 left-0 right-0 bottom-0 z-50 overflow-hidden w-full h-full max-h-screen bg-gray-500 bg-opacity-25 py-7 ease-in-out transition-all',
      { visibile: isOpen, invisible: !isOpen },
      // bottom closing animation takes 500ms
      { 'delay-500': !isOpen && placement === 'bottom' },
    )}
    onClick={onClose}
  >
    <div
      className={classNames(
        'absolute flex flex-col py-9 w-full sm:w-[500px] h-full bg-white-100 shadow-staticShadow transition-transform ease-in-out',
        {
          'top-0': placement === 'right' || placement === 'left',
        },
        {
          'translate-x-0 right-0': isOpen && placement === 'right',
          'translate-x-full right-0': !isOpen && placement === 'right',
        },
        {
          '-translate-x-0 left-0': isOpen && placement === 'left',
          '-translate-x-full left-0': !isOpen && placement === 'left',
        },
        {
          '-translate-x-1/2 left-1/2 rounded-md translate-y-full duration-500 bottom-4 top-4 h-fit':
            placement === 'bottom',
          '!translate-y-0 ': isOpen && placement === 'bottom',
        },
        { 'rounded-md overflow-hidden': rounded === 'md' },
        containerClassName,
      )}
      onClick={stopEventPropagation}
    >
      <div className="flex relative items-center justify-center w-full">
        <div className="absolute right-6 top-0 z-10">
          <CloseSheetButton onClick={onClose} />
        </div>
      </div>
      <div className="overflow-y-auto h-full flex justify-center">{children}</div>
    </div>
  </div>
);
